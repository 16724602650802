/**
 * Constantes spécifiques alvéole
 */
export const vousetessur = 'Mes factures';
export const txtnombredoc = 'document';
export const txtnombredocpluriel = 'documents';
export const titleAlveole = 'Mes factures';

// TODO arriver a faire fonctionner
export const alveoleLogoSvg = "./../images/icon-alv-factures.svg";

/************************ DeV ******************************************************/
export const zAPIdocuments = process.env.REACT_APP_API_FACTURES_DOCUMENT ;
export const zUrlBack = process.env.REACT_APP_API_FACTURES;
export const zUrlBackNotifications = process.env.REACT_APP_API_SERVICENOTIFICATION;
export const urlZeenDoc = process.env.REACT_APP_API_ZEENDOC;

/************************ LOCAL DeV ************************************************/
// export const zUrlBack = "https://localhost:8000"
// export const zAPIdocuments = "https://localhost:8000/customer-documents";
// export const zUrlBackNotifications = "https://localhost:8001";

export const Typedoc =[
    { id: 6, title: 'Factures' },
    { id: 7, title: 'Avoirs' },
];
export const Typedocid = [6,7];

/**
 * Fin constantes spécifiques alvéole
 */

/************************ Constantes ***********************************************/
export const zURLhome = process.env.REACT_APP_HOME_CUSTOMER;
export const zURLprofile = process.env.REACT_APP_PROFILE;